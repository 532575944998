import * as React from 'react';

import { Link } from 'react-router-dom';
import { Column, HeaderProps, SortByFn } from 'react-table';
import tinytime from 'tinytime';

import { TextCell } from '@components/shared/GridTable/components/inputs/TextCell';
import * as svgs from '@components/shared/TableFilters/components/svgs';
import { attrFunc } from '@components/StudiesApp/components/StudyPublished/components/buildParticipationsFilterDefs';
import { hasInterviewRoom } from '@components/StudiesApp/components/StudyPublished/utils';
import { FilterDefinition, FilterState } from 'components/shared/TableFilters/types';

import { ParticipationTableItem } from '../helpers/buildParticipantsColumns';
import { dateStatuses, TimestampStatus } from '../helpers/dateStatuses';

const template = tinytime('{MMMM} {DD} at {h}:{mm}{a}');
const fullTemplate = tinytime('{MMMM} {DD} {YYYY} at {h}:{mm}{a}');

export const ACTIVITY_TIMESTAMPS_MAPPING: Record<string, [string, Partial<keyof Participation>, string]> = {
  shortlisted: ['Shortlisted at', 'shortlisted_at', 'Timestamp indicating when a candidate was shortlisted.'],
  requested: ['Invited at', 'requested_at', 'Timestamp indicating when a candidate was invited to a study.'],
  invited: ['Sent screener at', 'invited_at', 'Timestamp indicating when a screener was sent to the candidate.'],
  applied: ['Applied at', 'applied_at', 'Timestamp indicating when a candidate applied to a study.'],
  booked: ['Booked at', 'booked_at', 'Timestamp indicating when a candidate booked a session.'],
  interview: [
    'Interview at',
    'interview_at',
    'Timestamp of when a participant’s interview is scheduled or took place.'
  ],
  updated: ['Last updated at', 'updated_at', 'Timestamp indicating the most recent update to a candidate’s data.'],
  started: ['Started at', 'started_at', 'Timestamp indicating when a participant started their session.'],
  completed: ['Completed at', 'completed_at', 'Timestamp indicating when a participant completed their session.'],
  rejected: ['Removed at', 'rejected_at', 'Timestamp indicating when a candidate was removed from the study.'],
  no_show: ['Updated at', 'no_show_at', 'Timestamp indicating when a participant was marked as a no-show.'],
  opted_out: [
    'Opted out at',
    'opted_out_at',
    'Timestamp indicating when a candidate opted out of communications or the study.'
  ],
  canceled: ['Canceled at', 'canceled_at', 'Timestamp indicating when a candidate canceled their participation.'],
  default: ['Last activity', 'updated_at', 'The most recent activity or interaction recorded for a candidate.']
};

export const CopyIcon: React.FC<React.PropsWithChildren<unknown>> = () => (
  <svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11 0.666664H3.00002C2.26669 0.666664 1.66669 1.26666 1.66669 2V11.3333H3.00002V2H11V0.666664ZM13 3.33333H5.66669C4.93335 3.33333 4.33335 3.93333 4.33335 4.66666V14C4.33335 14.7333 4.93335 15.3333 5.66669 15.3333H13C13.7334 15.3333 14.3334 14.7333 14.3334 14V4.66666C14.3334 3.93333 13.7334 3.33333 13 3.33333ZM5.66669 14H13V4.66666H5.66669V14Z'
      fill='#5850EC'
    />
  </svg>
);

export const displayUrl = (url: string): string => url.split('?')[0];

type StatusColumns = (args: {
  status: Participation['status'];
  style: Study['style'];
  onGQ: boolean;
  ParticipationOtherHeader: React.FC<React.PropsWithChildren<HeaderProps<ParticipationTableItem>>>;
  showInterviewColumn?: boolean;
  onClickParticipation: any;
  columnsWidth: Record<string, number> | null;
}) => Column<ParticipationTableItem>[];

export const statusColumns: StatusColumns = ({
  status,
  style,
  onGQ,
  ParticipationOtherHeader,
  onClickParticipation,
  showInterviewColumn,
  columnsWidth
}) => {
  const cols: Column<ParticipationTableItem>[] = [];
  if (status === 'booked') {
    cols.push({
      id: 'join_url',
      accessor: (row) => row.participation.join_url,
      Header: (props) => (
        <ParticipationOtherHeader {...props} description='Link used to access the scheduled interview or session.' />
      ),
      headerLabel: 'Call Link',
      width: columnsWidth?.join_url ?? 250,
      Cell: ({ value }) =>
        value ? (
          <span className='flex flex-row items-center'>
            <a className='text-gray-700 underline' target='_blank' rel='noreferrer' href={value}>
              {displayUrl(value)}
            </a>
            <span className='ml-2' data-clipboard-text={value}>
              <CopyIcon />
            </span>
          </span>
        ) : (
          ''
        )
    });
  }

  if (hasInterviewRoom({ status, style }) && showInterviewColumn) {
    let headerLabel = 'Interview';
    let linkLabel = 'Interview room';
    let description = 'Link to a participant’s interview room.';

    if (style === 'unmoderated_test') {
      headerLabel = 'Recording';
      linkLabel = 'View recording';
      description = 'Link to a participant’s unmoderated test recording.';
    }

    cols.push({
      id: 'interview',
      Header: (props) => <ParticipationOtherHeader {...props} description={description} />,
      headerLabel,
      accessor: (row) => row.participation.interview_path,
      width: columnsWidth?.interview ?? 250,
      Cell: ({ row, value }) => (
        <Link
          className='flex items-center space-x-2 pl-2 text-sm text-indigo-600 hover:text-indigo-700'
          to={value || ''}
        >
          {linkLabel}
          <svg className='icon-sm' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
            <path
              fill='currentColor'
              d='M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z'
            />
          </svg>
        </Link>
      )
    });
  }
  if (status === 'completed' && style === 'survey' && onGQ) {
    cols.push({
      id: 'survey',
      Header: (props) => (
        <ParticipationOtherHeader {...props} description='Link to a survey associated with a candidate.' />
      ),
      headerLabel: 'Survey',
      accessor: (row) => row.participation.study_path,
      width: columnsWidth?.survey ?? 250,
      Cell: ({ row }) => (
        <div
          className='flex cursor-pointer items-center space-x-2 text-sm text-indigo-600 hover:text-indigo-700'
          onClick={() => onClickParticipation(row.original)}
        >
          View survey
          <svg className='icon-sm' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
            <path
              fill='currentColor'
              d='M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z'
            />
          </svg>
        </div>
      )
    });
  }
  if (status === 'completed' && style === 'unmoderated_test') {
    cols.push({
      id: 'participation',
      Header: (props) => (
        <ParticipationOtherHeader {...props} description='Link to a participant’s unmoderated test recording.' />
      ),
      headerLabel: 'Recording',
      accessor: (row) => row.participation.interview_path,
      width: columnsWidth?.participation ?? 250,
      Cell: ({ value }) =>
        value ? (
          <a className='flex items-center space-x-2 text-sm text-indigo-600 hover:text-indigo-700' href={value}>
            View participation
            <svg className='icon-sm' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'>
              <path
                fill='currentColor'
                d='M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z'
              />
            </svg>
          </a>
        ) : (
          ''
        )
    });
  }

  return cols;
};

export const dateColumns = (
  style: Study['style'],
  hasScreener: boolean,
  onGQ: boolean,
  ParticipationOtherHeader: React.FC<React.PropsWithChildren<HeaderProps<ParticipationTableItem>>>,
  columnsWidth: Record<string, number> | null
): Column<ParticipationTableItem>[] => {
  return dateStatuses(style, hasScreener, onGQ).map((s) => dateColumn(s, ParticipationOtherHeader, columnsWidth));
};

export const dateColumn = (
  status: TimestampStatus,
  ParticipationOtherHeader: React.FC<React.PropsWithChildren<HeaderProps<ParticipationTableItem>>>,
  columnsWidth?: Record<string, number> | null
): Column<ParticipationTableItem> => {
  const [header, accessor, description] = ACTIVITY_TIMESTAMPS_MAPPING[status] || ACTIVITY_TIMESTAMPS_MAPPING.default;

  return {
    Header: (props) => <ParticipationOtherHeader {...props} description={description} />,
    headerLabel: header,
    accessor: (row) => row.participation[accessor],
    id: accessor,
    sortIconType: 'date',
    sortType: datetimeCompare,
    width: columnsWidth?.[accessor] ?? 160,
    Cell: (props) => (
      <TextCell
        {...props}
        tip={props.value ? fullTemplate.render(new Date(props.value)) : null}
        value={props.value ? template.render(new Date(props.value)) : ''}
      />
    )
  };
};

export const dateFilterDefs = (status: TimestampStatus): FilterDefinition<Participation> => {
  const [header, accessor] = ACTIVITY_TIMESTAMPS_MAPPING[status] || ACTIVITY_TIMESTAMPS_MAPPING.default;

  return {
    id: accessor,
    type: 'date',
    name: header,
    Icon: svgs.date,
    defaultOperator: 'is',
    func: (participation: Participation, state: FilterState<Participation>) => {
      return attrFunc(participation[accessor], state);
    }
  };
};

export const datetimeCompare: SortByFn<ParticipationTableItem> = (a, b, id, descending) => {
  const timeA = a.values[id] && typeof a.values[id] === 'string' ? Date.parse(a.values[id]) : a.values[id];
  const timeB = b.values[id] && typeof b.values[id] === 'string' ? Date.parse(b.values[id]) : b.values[id];

  if (!timeA) {
    return descending ? -1 : 1;
  }
  if (!timeB) {
    return descending ? 1 : -1;
  }
  if (descending) {
    return timeA < timeB ? -1 : 1;
  }
  return timeA > timeB ? 1 : -1;
};

export const studyLimitCompare: SortByFn<ParticipationTableItem> = (a, b, id, descending) => {
  const limitA = a.original.limit ? a.original.limit?.name || `Segment #${a.original.limit.order}` : 'No match';
  const limitB = b.original.limit ? b.original.limit?.name || `Segment #${b.original.limit.order}` : 'No match';

  return limitA.localeCompare(limitB);
};

export const booleanCompare = (a, b, id, desc) => {
  const valueA = a.values[id];
  const valueB = b.values[id];

  const isAValueBoolean = typeof valueA === 'boolean';
  const isBValueBoolean = typeof valueB === 'boolean';

  if (isAValueBoolean && isBValueBoolean) {
    return valueA ? 1 : -1;
  }

  if (valueA === null || valueA === undefined) return desc ? -1 : 1; // Null/undefined come last
  if (valueB === null || valueB === undefined) return desc ? 1 : -1; // anything else comes before null/undefined

  return 0;
};
