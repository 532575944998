import React, { EventHandler, forwardRef, useState } from 'react';

import cn from 'classnames';
import { UniqueIdentifier } from '@dnd-kit/core';

import { ErrorMessage, MediaInput } from '@components/common';
import { findItemDeep } from '@components/common/SortableTree';
import * as Models from '@components/SurveyBuilder/types/models';
import { CheckmarkInCircleSVG, ChevronDownSVG, CrossedCircleSVG, DragSVG, PlusSVG, TrashSVG } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';
import { focusInput } from '@components/utils';

export interface Props {
  id: UniqueIdentifier;
  childCount?: number;
  clone?: boolean;
  collapsed?: boolean;
  depth: number;
  disableInteraction?: boolean;
  disableSelection?: boolean;
  ghost?: boolean;
  disabled?: boolean;
  handleProps?: any;
  indicator?: boolean;
  indentationWidth: number;
  value: string;
  placeholder?: string;
  onChange?: EventHandler<React.FormEvent<HTMLInputElement>>;
  selected?: boolean;
  style?: React.CSSProperties;
  onSelect?: (id: UniqueIdentifier) => void;
  onAdd?: (id: UniqueIdentifier | null) => void;
  onCollapse?: (id: UniqueIdentifier) => void;
  onRemove?: (id: UniqueIdentifier) => void;
  wrapperRef?: (node: HTMLLIElement) => void;
  canSelect?: boolean;
  hasSelectedNode?: boolean;
  parent_id: UniqueIdentifier | null;
  position?: number;
  items?: Models.TreeItem[];
  isLoading?: boolean;
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      parent_id,
      position,
      items,
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onSelect,
      onRemove,
      style,
      value,
      wrapperRef,
      placeholder,
      onChange,
      onAdd,
      disabled,
      selected,
      canSelect,
      hasSelectedNode,
      isLoading,
      ...props
    },
    ref
  ) => {
    const [error, setError] = useState(false);

    return (
      <li className='list-none' ref={wrapperRef} {...props}>
        <div ref={ref} style={style}>
          <MediaInput
            isLoading={isLoading}
            name={`field-node-${parent_id}-${position || -1}`}
            wrapperClassName={cn('my-2 w-100', selected || hasSelectedNode ? 'bg-green-50' : 'bg-white')}
            className={cn('bg-transparent', childCount && childCount > 0 ? 'font-bold' : '')}
            style={
              {
                marginLeft: `${indentationWidth * depth}px`
              } as React.CSSProperties
            }
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();

                if (!value || isLoading) return;

                onAdd?.(parent_id);
              }
            }}
            value={value}
            placeholder={placeholder}
            onChange={(e) => {
              setError(!e.currentTarget.value);

              onChange?.(e);
            }}
            start={
              !disabled ? (
                <div className='flex h-full w-10 items-center justify-center border-r border-gray-200'>
                  <Tooltip maxWidth={240} content={collapsed ? 'Expand' : 'Collapse'}>
                    <button
                      className={cn(
                        'flex h-6 w-6 transform items-center justify-center rounded-full transition duration-200 ease-in-out',
                        {
                          ['-rotate-90']: collapsed || !onCollapse,
                          'hover:bg-gray-100': onCollapse
                        }
                      )}
                      disabled={!childCount}
                      onClick={() => onCollapse?.(id)}
                      aria-label={collapsed || !onCollapse ? 'Expand' : 'Collapse'}
                      type='button'
                    >
                      <ChevronDownSVG className={onCollapse ? 'text-gray-700' : 'text-gray-400'} />
                    </button>
                  </Tooltip>
                </div>
              ) : (
                (null as any)
              )
            }
            end={
              !disabled ? (
                <div className='flex h-full items-center space-x-1 pr-3'>
                  {canSelect ? (
                    <Tooltip maxWidth={240} content={`${selected ? 'Deselect' : 'Select'} as a correct answer`}>
                      <button
                        className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-gray-100'
                        onClick={() => onSelect?.(id)}
                        aria-label={selected ? `Deselect ${value}` : `Select ${value}`}
                        type='button'
                      >
                        {selected ? (
                          <CrossedCircleSVG className='text-gray-700' />
                        ) : (
                          <CheckmarkInCircleSVG className='text-gray-700' />
                        )}
                      </button>
                    </Tooltip>
                  ) : (
                    <div className='h-4 w-4 flex-shrink-0' />
                  )}
                  <Tooltip maxWidth={240} content='Add child node'>
                    <button
                      className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-gray-100'
                      onClick={() => {
                        onAdd?.(id);
                        const parent = findItemDeep(items || [], id);
                        const newNodeIndex = parent ? (parent.nodes?.length || 0) + 1 : null;

                        focusInput(`field-node-${id}-${newNodeIndex}`);
                      }}
                      aria-label='Add new node'
                      type='button'
                    >
                      <PlusSVG className='text-gray-700' />
                    </button>
                  </Tooltip>
                  <Tooltip maxWidth={240} content='Delete node'>
                    <button
                      className='flex h-6 w-6 items-center justify-center rounded-full hover:bg-gray-100'
                      onClick={() => onRemove?.(id)}
                      aria-label={`Delete ${value}`}
                      type='button'
                    >
                      <TrashSVG className='text-gray-700' />
                    </button>
                  </Tooltip>

                  <Tooltip maxWidth={240} isDisabled={disableInteraction} content='Drag and drop'>
                    <button
                      cursor='grab'
                      data-cypress='draggable-handle'
                      className={cn('flex h-6 w-6 items-center justify-center rounded-full', {
                        'cursor-grab hover:bg-gray-100': !disableInteraction,
                        'cursor-not-allowed': disableInteraction
                      })}
                      type='button'
                      {...handleProps}
                    >
                      <DragSVG className='text-gray-700' />
                    </button>
                  </Tooltip>
                </div>
              ) : null
            }
          />
          {error && !clone && (
            <ErrorMessage
              icon='warning'
              aria-label='Error message'
              size='small'
              style={
                {
                  marginLeft: `${indentationWidth * depth}px`
                } as React.CSSProperties
              }
            >
              The node label is required.
            </ErrorMessage>
          )}
        </div>
      </li>
    );
  }
);
