import React, { useState } from 'react';

import cn from 'classnames';
import pluralize from 'pluralize';

import { Checkbox, Text } from '@components/common';
import { CaretDownSVG, InfoCircleIcon } from '@components/svgs';
import { Tooltip } from '@components/Tooltip';

interface Props {
  totalCount: number;
  inviteableCount: number;
  contactableCount: number;
  previewCandidate?: Candidate;
  resend: boolean;
  resendCount: number;
  ineligibleCount: number;
  excludeIneligible: boolean;
  setExcludeIneligible: (excludeIneligible: boolean) => void;
  studyLimits?: StudyLimit[];
  studyLimitMatches?: Record<number, number>;
}

export const IneligibleCandidates: React.FC<React.PropsWithChildren<Props>> = ({
  totalCount,
  inviteableCount,
  previewCandidate,
  contactableCount,
  resend,
  resendCount,
  ineligibleCount,
  excludeIneligible,
  setExcludeIneligible,
  studyLimits,
  studyLimitMatches
}) => {
  const [limitsOpen, setLimitsOpen] = useState(false);
  const shouldShowExcludeIneligibleOption = ineligibleCount > 0;
  const toBeSentString = () => {
    let text = '';
    let actualCount = contactableCount;

    if (excludeIneligible) actualCount -= ineligibleCount;
    if (!resend) actualCount = Math.max(0, actualCount - resendCount); // Ensure count doesn't go negative

    if (actualCount === 1 && totalCount === 1) {
      text = `${previewCandidate?.name || 'a candidate'}`;
    } else if (actualCount === totalCount) {
      text = `${actualCount} candidates`;
    } else {
      text = `${actualCount} of ${totalCount} candidates`;
    }

    return text;
  };

  return (
    <>
      <div className='h500-bold mb-2'>To</div>
      <div className='flex'>
        <div className='flex-1'>
          <span className='h500 block'>{toBeSentString()}</span>
        </div>
        {studyLimits && studyLimits.length > 0 && (
          <button
            name='open segments'
            aria-label='open segments'
            className='h400 text-indigo-600 hover:text-indigo-700'
            onClick={() => setLimitsOpen(!limitsOpen)}
          >
            <div className='flex items-center'>
              <div className='font-bold'>{pluralize('segments', studyLimits.length, true)}</div>
              <CaretDownSVG className={cn('ml-2', { 'rotate-180 transform': limitsOpen })} />
            </div>
          </button>
        )}
      </div>
      {limitsOpen && studyLimits && (
        <div className='mt-2 space-y-3'>
          {studyLimits.map((limit) => (
            <div
              key={limit.id}
              className='flex flex-row items-center space-x-2 rounded-md border border-gray-200 px-4 py-2'
            >
              <Text className='flex-1' h='400'>
                {limit.name ? limit.name : `Segment #${limit.order}`}
              </Text>
              {studyLimitMatches && (
                <Text color='gray-500' h='400'>
                  {pluralize('matches', studyLimitMatches[limit.id] || 0, true)}
                </Text>
              )}
            </div>
          ))}
        </div>
      )}
      {shouldShowExcludeIneligibleOption && (
        <div className='flex items-center space-x-2 py-4'>
          <Checkbox
            name='exclude_ineligible'
            onChange={(val) => setExcludeIneligible(val)}
            selected={excludeIneligible}
          />
          <div className='flex flex-row items-center space-x-2'>
            <Text>
              Exclude {ineligibleCount} ineligible {pluralize('candidates', ineligibleCount)}
            </Text>
            <Tooltip
              content="Ineligible candidates are people who don't meet the contact eligibility criteria set by the Admin on your
              account."
            >
              <InfoCircleIcon className='h-4 w-4' />
            </Tooltip>
          </div>
        </div>
      )}
    </>
  );
};
