import * as React from 'react';
import { useMemo } from 'react';

import { format } from 'date-fns';
import { Navigate, Route, Routes, useMatch } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Tabs } from '@components/common';
import { ExportCsvButton } from '@components/common/ExportCsvButton';
import { PageTitle } from '@components/common/helmets';
import { ScreenerResultsIndividual, ScreenerResultsSummary } from '@components/ScreenerResults';
import { CopyURLButton } from '@components/shared/CopyURLButton';
import { PageHeader } from '@components/shared/PageHeader';
import { buildCsvData } from '@components/StudiesApp/components/StudyPublished/components/Summary/utils';
import { usePermission } from '@hooks/usePermission';
import { useScreenerResults } from '@hooks/useScreenerResults';

const TAB_LABELS = {
  overall: 'Overall',
  individual: 'Individual'
};

interface Props {
  study: Study;
  setBackgroundTask: (tasks: BackgroundTask) => void;
}

export const Summary: React.FC<React.PropsWithChildren<Props>> = ({ study, setBackgroundTask }) => {
  const match = useMatch(`studies/:id/summary/:tab`);

  const { results, isLoading } = useScreenerResults(study.id, study?.survey_screener?.id);

  const navigate = useNavigate();

  const isAdmin = usePermission('isAdmin')();

  const { headers, rows } = useMemo(() => buildCsvData(results.individual), [results.individual]);

  const csvHeaders = headers.map((label) => ({ label, key: label }));

  const filename = `study_${study.id}_results_${format(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`;

  return (
    <>
      <PageTitle>Summary</PageTitle>
      <PageHeader
        transparent
        hideDefaultButtons
        renderTabs={() => (
          <Tabs
            current={match?.params.tab || 'overall'}
            labels={TAB_LABELS}
            onSelect={navigate}
            className='px-page flex items-center border-t border-gray-200'
            tabs={['overall', 'individual']}
          />
        )}
        renderCta={() => (
          <div className='flex flex-nowrap items-center space-x-2'>
            <ExportCsvButton
              disabled={!isAdmin}
              disabledTooltip='Only admin users may export Survey results'
              medium
              data={rows}
              headers={csvHeaders}
              filename={filename}
            />

            <CopyURLButton medium icon='link' noTippy text={window.location.href} trackEvent='copied_summary_link'>
              Copy link
            </CopyURLButton>
          </div>
        )}
        h1='Summary'
      />
      <div className='px-page py-gutter min-h-full'>
        <Routes>
          <Route path='*' element={<Navigate replace to='overall' />} />
          <Route
            path='/overall'
            element={<ScreenerResultsSummary isLoading={isLoading} results={results?.summary as any} />}
          />
          <Route
            path='/individual'
            element={
              <ScreenerResultsIndividual
                withBorders
                isLoading={isLoading}
                setBackgroundTask={setBackgroundTask}
                study={study as Study}
                responses={results?.individual}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
};
