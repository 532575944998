import React, { FC, useEffect, useState } from 'react';

import { Alert, Button, Pill, Select, Text } from '@components/common';
import { useParticipantRecording } from '@components/ScreenSharingProvider';
import { Enums, getBlockLabel, Models } from '@components/SurveyBuilder';
import { MicrophoneSVG, ScreenSVG, VideoCameraSVG } from '@components/svgs';
import { TaskHeader } from '@components/Unmoderated/components/TaskHeader';

import { useUnmoderatedContext } from '../hooks/useUnmoderatedContext';

import { ResponsiveCard } from './ResponsiveCard';

type PermissionFieldProps = {
  label: string;
  description: string;
  permission: Enums.DevicePermission;
  isChecked: boolean;
  isErrored: boolean;
  onClick?: () => void;
  icon: React.FC<React.PropsWithChildren<React.SVGProps<SVGSVGElement>>>;
  renderSelect?: () => React.ReactNode;
  renderWarning?: () => React.ReactNode;
};

interface Props {
  block: Models.Block<Enums.Kind.permissions>;
  isPreview: boolean;
  onSubmit: () => Promise<void>;
}

const PermissionField: FC<PermissionFieldProps> = ({
  icon: Icon,
  isChecked,
  isErrored,
  label,
  description,
  permission,
  renderWarning,
  onClick,
  renderSelect
}) => {
  if (permission === 'disabled') {
    return null;
  }

  return (
    <div className='mb-6 w-full'>
      <div className='mb-1 flex items-center justify-between'>
        <div className='flex items-center'>
          <Icon className='mr-2 flex-shrink-0' />
          <Text bold h='400'>
            {label}
          </Text>
        </div>

        {isChecked && <Pill color='green'>ENABLED</Pill>}
      </div>
      <div className='ml-6'>
        <Text className='text-custom-brand-secondary' h='200' mb='4'>
          {description}
        </Text>
        {renderWarning?.()}
        {!isChecked && !isErrored && onClick && (
          <Button
            className='btn-custom-brand'
            noStyle
            small
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick?.();
            }}
          >
            Enable
          </Button>
        )}
        {isChecked && renderSelect?.()}
        {!isChecked && !isErrored && !onClick && (
          <Button className='btn-custom-brand' small disabled noStyle>
            Enable
          </Button>
        )}
        {isErrored && (
          <Text color='red-600' h='200'>
            Ensure your device is set up correctly and is accessible to the browser.
          </Text>
        )}
      </div>
    </div>
  );
};

export const Permissions: FC<Props> = ({ block, isPreview, onSubmit }) => {
  const [waitingForRecording, setWaitingForRecording] = useState(false);
  const [isMinimized, setIsMinimized] = useState(false);

  const {
    camState,
    micState,
    hasCamError,
    hasMicError,
    isSharingScreen,
    readyToJoin,
    isJoined,
    joinCall,
    startScreenShare,
    isRecording,
    cameras,
    microphones,
    setCamera,
    setMicrophone,
    canStartRecording,
    startRecording,
    isDisabled
  } = useParticipantRecording();

  const { blocks, deviceType, topBarUnmoderatedLayout } = useUnmoderatedContext();

  const { camera, microphone, screen } = block.blockable;

  const websiteTestBlocks = blocks.filter(
    (block) => block.kind === Enums.Kind.websiteTest
  ) as Models.Block<Enums.Kind.websiteTest>[];

  const needsFullWindowMode = websiteTestBlocks.some(
    (websiteTestBlock) =>
      !websiteTestBlock.blockable.is_iframable || websiteTestBlock.blockable.settings?.open_in_new_tab
  );

  const screenShareCurrentTab = block.blockable.settings?.screen_share_current_tab && !needsFullWindowMode;

  useEffect(() => {
    if (waitingForRecording && (isPreview || isRecording)) {
      onSubmit();
    }
  }, [isRecording, waitingForRecording, isPreview]);

  useEffect(() => {
    if (isDisabled) {
      onSubmit();
    }
  }, [isDisabled]);

  useEffect(() => {
    if (camera === 'disabled' && microphone === 'disabled' && !isJoined && readyToJoin) {
      joinCall();
    }
  }, [camera, microphone, isJoined, readyToJoin]);

  const micOptions = microphones.map(({ device: { label, deviceId } }) => ({
    label,
    value: deviceId
  }));

  const selectedMic = microphones.find(({ selected }) => selected)?.device.deviceId;

  const camOptions = cameras.map(({ device: { label, deviceId } }) => ({
    label,
    value: deviceId
  }));

  const selectedCam = cameras.find(({ selected }) => selected)?.device.deviceId;

  const renderActions = () => (
    <div className='mt-4'>
      <PermissionField
        icon={MicrophoneSVG}
        renderSelect={() =>
          !!micOptions.length && (
            <Select
              className='truncate'
              h={8}
              onChange={setMicrophone}
              options={micOptions}
              value={selectedMic || micOptions[0].value}
            />
          )
        }
        label='Microphone'
        permission={microphone}
        isChecked={micState === 'granted'}
        isErrored={hasMicError}
        description='This is so we can hear you think out loud.'
        onClick={readyToJoin ? joinCall : undefined}
      />
      <PermissionField
        icon={VideoCameraSVG}
        renderSelect={() =>
          !!camOptions.length && (
            <Select h={8} onChange={setCamera} options={camOptions} value={selectedCam || camOptions[0].value} />
          )
        }
        label='Camera'
        permission={camera}
        isChecked={camState === 'granted'}
        isErrored={hasCamError}
        description='So we can observe any facial expressions.'
        onClick={readyToJoin ? joinCall : undefined}
      />
      {deviceType === 'desktop' && (
        <PermissionField
          icon={ScreenSVG}
          label='Screen recording'
          permission={screen}
          isErrored={false}
          isChecked={isSharingScreen}
          description='So we can observe you clicking through the test.'
          renderWarning={() =>
            needsFullWindowMode ? (
              <Alert className='mb-4' type='warning' heading='Screen recording'>
                <div data-testid='Warning message'>
                  This test will open URLs in a new tab. Make sure to record the <strong>entire screen</strong> to
                  capture all interactions.
                </div>
              </Alert>
            ) : undefined
          }
          onClick={
            isJoined
              ? () => {
                  startScreenShare({
                    displayMediaOptions: {
                      // @ts-expect-error - Daily.js types are missing this property
                      preferCurrentTab: screenShareCurrentTab,
                      video: {
                        displaySurface: needsFullWindowMode ? 'window' : 'browser'
                      },
                      selfBrowserSurface: 'include',
                      surfaceSwitching: 'include',
                      systemAudio: 'exclude'
                    }
                  });
                }
              : undefined
          }
        />
      )}
    </div>
  );

  const renderFooter = () => (
    <Button
      type='button'
      className='btn-custom-brand'
      icon='arrowRight'
      aria-label='Start recording and continue'
      loading={waitingForRecording}
      black={!topBarUnmoderatedLayout}
      small
      fullWidth={!topBarUnmoderatedLayout}
      noStyle
      disabled={!isPreview && !canStartRecording}
      onClick={() => {
        setWaitingForRecording(true);

        if (!isPreview && canStartRecording) {
          startRecording();
        }
      }}
    >
      Start recording and continue
    </Button>
  );

  if (deviceType === 'desktop' && topBarUnmoderatedLayout) {
    return (
      <div className='flex h-screen w-full flex-col overflow-hidden bg-white pt-4'>
        <TaskHeader
          isMinimized={isMinimized}
          setIsMinimized={setIsMinimized}
          block={block}
          renderOptions={renderFooter}
        />
        <div className='flex w-full flex-1 overflow-hidden bg-gray-50'>
          <div className='flex w-full flex-1 flex-col items-center overflow-auto'>
            <div className='w-1/2 max-w-3xl px-4 py-6 text-left'>
              <div className='rounded-lg border border-gray-200 bg-white px-6 pt-2'>{renderActions()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ResponsiveCard
      blockKind={getBlockLabel(block.kind)}
      blockPosition={block.position}
      description={block.description}
      title={block.title}
      totalBlocks={blocks.length}
      renderFooter={renderFooter}
    >
      {renderActions()}
    </ResponsiveCard>
  );
};
