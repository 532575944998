import * as React from 'react';
import { useMemo, useRef, useState } from 'react';

import { MultiselectDropdown } from '@components/common/MultiselectDropdown';
import { MultiselectDropdownItem } from '@components/shared/MultiselectCombobox';
import { useOnClickOutside } from '@components/utils';
import { useSearch } from '@hooks/useSearch';
import { Option, Tabs } from 'components/common';

export type GridTableTabs = {
  labels: Record<string, string>;
  filterFunc: (value: string, tab: string) => boolean;
};

interface ShowColumnsDropdownProps {
  onClose: () => void;
  columns: Option[];
  visibleColumns: string[];
  showColumns: (columns: string[]) => void;
  tabs?: GridTableTabs;
}

export const ColumnsDropdown: React.FC<React.PropsWithChildren<ShowColumnsDropdownProps>> = ({
  tabs,
  onClose,
  columns,
  visibleColumns,
  showColumns
}) => {
  const [search, setSearch] = useState('');
  const ref = useRef<HTMLDivElement>(null);
  const [tab, setTab] = useState(tabs ? Object.keys(tabs.labels)?.[0] : '');

  useOnClickOutside(ref, onClose);

  const sortedColumns: MultiselectDropdownItem<string>[] = useMemo(() => {
    return columns
      .filter(
        ({ value }) => value !== 'name' && value !== 'customer:name' && (tabs ? tabs.filterFunc(value, tab) : true)
      )
      .sort((a, b) => a.label.localeCompare(b.label))
      .sort((a) => (visibleColumns.includes(a.value) ? -1 : 1));
  }, [columns, visibleColumns, tab]);

  const { results } = useSearch(sortedColumns, ['label'], search, [search, sortedColumns]);

  const onSave = (cols: string[]) => {
    const columns = [...cols];
    if (!columns.includes('name')) {
      columns.push('name');
    }
    showColumns(columns);
  };

  return (
    <MultiselectDropdown
      className='max-w-md'
      onSearch={setSearch}
      dropdownClass='absolute top-8 right-8 w-112 xx-multi-dropdown'
      selectedValues={visibleColumns}
      onSave={onSave}
      ref={ref}
      items={results}
    >
      {tabs && (
        <div className='mb-2 w-auto'>
          <Tabs tabs={Object.keys(tabs.labels)} labels={tabs.labels} current={tab || ''} onSelect={setTab} />
        </div>
      )}
    </MultiselectDropdown>
  );
};
