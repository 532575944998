import React, { FC, useRef } from 'react';

import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import { uid } from '@components/utils';

import { surveyBuilderApi as api } from '../api';
import { BLOCK_KIND_LABELS, LOCKED_BLOCK_TYPES } from '../constants';
import { getBlockLabel } from '../helpers/getBlockLabel';
import { useBlockErrors } from '../hooks/useBlockErrors';
import { usePatchBlockPosition } from '../hooks/usePatchBlockPosition';
import { usePatchBlocksQuery } from '../hooks/usePatchBlocksQuery';
import * as Enums from '../types/enums';
import * as Models from '../types/models';

import { AddBlockButton } from './AddBlockButton';
import { Block } from './Block';
import { BlockIcon } from './BlockIcon';

interface Props {
  active?: Models.Block;
  onCreateBlock?: (kind: Models.BlockKind) => Promise<Models.Block | void>;
  setActive?: (block?: Models.Block) => void;
  surveyId: number;
}

const DEFAULT_BLOCK_TITLE = 'Untitled block';

const createDummyBlock = (kind: Models.BlockKind, surveyId: number): Models.Block => {
  const blockable: Models.Blockable = { id: uid() };

  return {
    blockable_id: blockable.id,
    blockable,
    description: null,
    id: uid(),
    isNew: true,
    kind,
    position: -1,
    required: false,
    study_id: surveyId,
    title: '',
    owner_id: uid(),
    image_url: null,
    token: ''
  };
};

export const Blocks: FC<Props> = ({ active, onCreateBlock, setActive, surveyId }) => {
  const scrollableWrapperRef = useRef<HTMLDivElement>(null);

  const { data: initialBlocks = [] } = api.useGetSurveyBuilderBlocksQuery(surveyId);
  const [destroyBlock] = api.useDestroySurveyBuilderBlockMutation();
  const [duplicateBlock] = api.useDuplicateSurveyBuilderBlockMutation();
  const [updatePosition] = api.useUpdateSurveyBuilderBlockPositionMutation();

  const [{ add, remove, update }] = usePatchBlocksQuery(surveyId);
  const [patchBlockPosition] = usePatchBlockPosition(surveyId);
  const { errors } = useBlockErrors(initialBlocks);

  const blocks = initialBlocks.filter(({ kind }) => kind !== Enums.Kind.thankYou);
  const thankYouBlock = initialBlocks.find(({ kind }) => kind === Enums.Kind.thankYou);

  const isLocked = (kind: Models.BlockKind) => LOCKED_BLOCK_TYPES.includes(kind);

  const isActive = (block: Models.Block) => block.id === active?.id;

  const scrollToBottom = () => {
    if (scrollableWrapperRef.current) {
      scrollableWrapperRef.current.scrollTo({ top: scrollableWrapperRef.current.scrollHeight });
    }
  };

  const handleOnClick = (block: Models.Block) => {
    setActive?.(block);
  };

  const handleOnDelete = async (block: Models.Block) => {
    if (active?.id === block.id) {
      setActive?.(undefined);
    }

    remove(block);

    if (!block.isNew) {
      destroyBlock(block.id);
    }
  };

  const handleOnDuplicate = async (block: Models.Block) => {
    const duplicated = await duplicateBlock(block.id).unwrap();

    add(duplicated);

    setActive?.(duplicated);

    requestAnimationFrame(scrollToBottom);
  };

  const handleOnDragEnd = async (result: DropResult) => {
    const { destination, draggableId, source } = result;
    const blockId = Number(draggableId);
    const block = blocks.find(({ id }) => id === blockId);

    if (block?.isNew) {
      return;
    }

    const [newBlock] = blocks.filter(({ isNew }) => isNew);

    if (newBlock && destination && destination.index >= newBlock.position) {
      return;
    }

    if (destination && destination.index !== source.index) {
      try {
        updatePosition({ id: blockId, position: destination.index });

        patchBlockPosition(source.index, destination.index);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleOnAddBlock = async (kind: Models.BlockKind) => {
    const dummyBlock = createDummyBlock(kind, surveyId);

    add(dummyBlock);
    setActive?.(dummyBlock);

    const block = await onCreateBlock?.(kind);

    if (block) {
      setActive?.(block);

      update(dummyBlock.id, { ...block, isNew: false });
    } else {
      remove(dummyBlock);
      setActive?.(undefined);
    }

    requestAnimationFrame(scrollToBottom);
  };

  const getWarningMessage = (block: Models.Block) => {
    if (errors) {
      const blockErrors = errors[block.id];

      if (blockErrors) {
        return blockErrors.message;
      }
    }
  };

  return (
    <div className='relative flex h-full flex-col items-stretch'>
      <section ref={scrollableWrapperRef} className='h-full min-h-0 overflow-auto p-6'>
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId='list'>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {blocks.map((block) => (
                  <Draggable
                    key={block.id}
                    draggableId={String(block.id)}
                    index={block.position}
                    isDragDisabled={isLocked(block.kind) || block.isNew}
                  >
                    {(draggableProvided) => (
                      <Block
                        aria-label={`${getBlockLabel(block.kind)} block`}
                        className='mb-4 rounded border border-gray-200 hover:border-indigo-600'
                        dragHandleProps={draggableProvided.dragHandleProps}
                        icon={<BlockIcon kind={block.kind} />}
                        title={block.title || DEFAULT_BLOCK_TITLE}
                        isActive={isActive(block)}
                        isDraggable={!isLocked(block.kind) && !block.isNew}
                        hasOptions={!isLocked(block.kind)}
                        onClick={() => handleOnClick(block)}
                        onDelete={() => handleOnDelete(block)}
                        onDuplicate={() => handleOnDuplicate(block)}
                        ref={draggableProvided.innerRef}
                        subtitle={getBlockLabel(block.kind)}
                        warningMessage={getWarningMessage(block)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            handleOnClick(block);
                          }
                        }}
                        {...draggableProvided.draggableProps}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <AddBlockButton onPickBlock={handleOnAddBlock} />

        {thankYouBlock && (
          <Block
            subtitle={BLOCK_KIND_LABELS[thankYouBlock.kind]}
            className='mt-4 w-full rounded border border-gray-200 hover:border-indigo-600'
            title={thankYouBlock.title || DEFAULT_BLOCK_TITLE}
            icon={<BlockIcon kind={Enums.Kind.thankYou} />}
            isActive={isActive(thankYouBlock)}
            onClick={() => handleOnClick(thankYouBlock)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleOnClick(thankYouBlock);
              }
            }}
          />
        )}
      </section>
    </div>
  );
};
