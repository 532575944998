import * as React from 'react';

import classNames from 'classnames';

import { Text } from '@components/common';
import { LOCATION } from '@components/StudiesApp/components/StudyDraft/pages/Calendar/constants';

import { DiffRow } from './DiffRow';

const AUTOMATED_PROVIDERS: VideoUrlType[] = ['zoom', 'google_meet', 'webex'];

const NoneText = () => (
  <Text h='400' color='gray-500'>
    None.
  </Text>
);

export { NoneText };

type Props = {
  before?: DeepPartial<CalendarBookingDetailEvent> | null;
  after?: DeepPartial<CalendarBookingDetailEvent> | null;
};

type LocationContentProps = {
  provider?: VideoUrlType;
  url?: string;
  isStrikethrough?: boolean;
  isRightSide?: boolean;
};

const LocationContent = ({ provider, url, isStrikethrough, isRightSide }: LocationContentProps) => {
  if (!provider) return <NoneText />;

  const isAutomatedProvider = AUTOMATED_PROVIDERS.includes(provider);
  const shouldShowNewLink = isRightSide && isAutomatedProvider;

  return (
    <div
      className={classNames('flex flex-col space-y-2', {
        'line-through': isStrikethrough,
        'items-end': isRightSide
      })}
    >
      <div className='flex items-center space-x-2'>
        <Text h='400'>{LOCATION[provider]}</Text>
      </div>
      {shouldShowNewLink ? (
        <div className='flex items-center space-x-2'>
          <Text h='400' color='gray-500'>
            a new link will be generated
          </Text>
        </div>
      ) : url ? (
        <div className='flex items-center space-x-2'>
          <Text h='400' color='gray-500' truncate>
            {url}
          </Text>
        </div>
      ) : (
        <NoneText />
      )}
    </div>
  );
};

export const LocationDiff = ({ before, after }: Props) => {
  if (!before && !after) return null;

  const isAutomatedProvider =
    after?.conferencing?.provider && AUTOMATED_PROVIDERS.includes(after.conferencing.provider);
  const shouldShowNewLink = isAutomatedProvider && before?.conferencing?.provider !== after?.conferencing?.provider;

  return (
    <DiffRow
      leftSide={
        <LocationContent provider={before?.conferencing?.provider} url={before?.conferencing?.url} isStrikethrough />
      }
      rightSide={
        after?.conferencing?.provider ? (
          <LocationContent
            provider={after.conferencing.provider}
            url={shouldShowNewLink ? 'a new link will be generated' : after?.conferencing?.url}
            isRightSide
          />
        ) : (
          <NoneText />
        )
      }
    />
  );
};
