import * as React from 'react';
import { useEffect, useState } from 'react';

import { Card, PopperDropdown, PopperDropdownAnchor, Text } from '@components/common';
import * as Models from '@components/SurveyBuilder/types/models';
import { Tooltip } from '@components/Tooltip';
import { setHash } from '@components/utils';
import { StudyScreenerBuilder } from 'components/StudiesApp/components/StudyDraft/components/StudyScreenerBuilder';

const SURVEY_HEADINGS: Omit<Record<Study['style'] & 'focus_group', string>, 'repo_only' | 'phantom'> = {
  survey: 'Survey',
  unmoderated_test: 'Test',
  panel: 'Signup form'
};

const Wrapper = ({ children, shouldAddCardWrapper }) => (
  <section className='px-page py-gutter flex flex-1'>
    {shouldAddCardWrapper ? <Card className='w-full'>{children}</Card> : children}
  </section>
);

interface Props {
  study: Study;
  pathType: string;
  screenerType: ScreenerType;
  updateStudy: (study: { id: number } & Partial<Study>) => void;
}

export const ScreenerTab: React.FC<React.PropsWithChildren<Props>> = ({
  study,
  pathType,
  screenerType,
  updateStudy
}) => {
  const [activeBlock, setActiveBlock] = useState<Models.Block>();
  const isPreScreener: boolean = screenerType === 'pre';

  const screener = isPreScreener ? study.pre_screener : study.survey_screener;

  const currentBlockPreviewDisabled = !activeBlock || activeBlock.kind === 'permissions' || !activeBlock.token;

  if (!screener) return null;

  return (
    <div className='h-screen-with-fixed-header flex flex-1 flex-col overflow-auto'>
      <section className='border-b border-gray-200'>
        <header className='px-page flex h-20 items-center justify-between'>
          <Text as='h2' className='text-2xl' bold>
            {isPreScreener ? 'Screener' : SURVEY_HEADINGS[study.style]}
          </Text>
          <div id='post-publish-survey-actions'>
            {study.style === 'unmoderated_test' && pathType === 'test' && (
              <PopperDropdown medium text='Preview' icon='eye'>
                <PopperDropdownAnchor target='_blank' href={`/studies/${study.id}/preview/survey`}>
                  Preview from start of test
                </PopperDropdownAnchor>
                <Tooltip isDisabled={!currentBlockPreviewDisabled} content='Cannot preview this block.'>
                  <PopperDropdownAnchor
                    target='_blank'
                    disabled={currentBlockPreviewDisabled}
                    href={`/studies/${study.id}/preview/survey#${activeBlock?.token}`}
                  >
                    Preview from current block
                  </PopperDropdownAnchor>
                </Tooltip>
              </PopperDropdown>
            )}
          </div>
        </header>
      </section>

      <Wrapper shouldAddCardWrapper={study.style !== 'unmoderated_test' || isPreScreener}>
        <StudyScreenerBuilder
          page='study'
          study={study}
          screener={screener as Screener}
          preScreener={isPreScreener}
          onSave={updateStudy}
          setActiveBlock={setActiveBlock}
        />
      </Wrapper>
    </div>
  );
};
