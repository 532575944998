import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import ReactSkeleton from 'react-loading-skeleton';

import { api } from '@api/reduxApi';
import { MultiselectDropdownItem } from '@components/shared/MultiselectCombobox';
import { useColumnLabels } from '@components/shared/Table/hooks/useGetColumnLabels';
import { buildColumnTabs } from '@components/StudiesApp/components/StudyPublished/utils';
import { uniq } from '@components/utils';
import { useCandidateAttrs } from '@hooks/useCandidateAttrs';
import { useFeature } from '@hooks/useFeature';
import { EXTERNAL_ATTRS } from 'components/config';
import { getPreScreenerFieldId } from 'components/StudiesApp/components/StudyPublished/components/buildParticipationsFilterDefs';

import { Table } from '../Table';
import { TableSidebar } from '../TableSidebar';

import { useColumnDefinitions } from './hooks/useColumnDefinitions';
import { useParticipationsLabels } from './hooks/useParticipationsLabels';
import { useUpdateParticipation } from './hooks/useUpdateParticipation';
import { useVisibleColumns } from './hooks/useVisibleColumns';
import { EDITABLE_COLUMNS, STATIC_COLUMNS } from './constants';

interface Props {
  participations: Participation[];
  study: Study;
  currentPage?: number;
  sortBy?: CollectionView['sort'];
  selectedRowIds?: number[];
  onSelectRowIds?: (rows: number[]) => void;
  updateEntireParticipation?: (participation: Participation) => void;
  onParticipationClick?: (participation: Participation) => void;
  onTimeProposalClick?: (participation: Participation) => void;
  setBackgroundTask: (task: BackgroundTask) => void;
  participationStatus: ParticipationStatus;
  addFilter: (filter: string) => void;
  isLocked?: boolean;
  onSort?: (sort: CollectionView['sort']) => void;
  onSelectAllClick?: (selected: boolean) => void;
}

export const ParticipationsTableComponent: FC<Props> = ({
  participations,
  currentPage,
  selectedRowIds = [],
  sortBy,
  study,
  onSelectRowIds,
  updateEntireParticipation,
  onParticipationClick,
  setBackgroundTask,
  participationStatus,
  addFilter,
  onTimeProposalClick,
  onSort,
  onSelectAllClick
}) => {
  const [visibleAttrs, setVisibileAttrs] = useState<string[]>(study.visible_attrs);

  const serverSideTableEnabled = useFeature('serverside_participations');

  const { candidateAttrs, isLoading } = useCandidateAttrs();

  const { data: studyLimits, isLoading: limitsLoading } = api.useGetStudyLimitsQuery(study.id);
  const { data: studyLimitsProgress, isLoading: limitsProgressLoading } = api.useGetStudyLimitsProgressQuery(study.id, {
    skip: !studyLimits || studyLimits.length < 1
  });

  const partyIdToLimit = useCallback(
    (partyId: number) => {
      const limits = studyLimitsProgress || {};

      const limitId = Object.keys(limits).find((id) => limits[id].participation_ids.includes(partyId));
      const limit = studyLimits?.find((l) => String(l.id) === limitId);
      return limit ? limit.name || `Segment #${limit.order}` : 'No match';
    },
    [studyLimits, studyLimitsProgress]
  );

  const participantsLabels = useParticipationsLabels({ study });

  const getColumnLabel = useColumnLabels(participantsLabels);

  const columns = useColumnDefinitions({
    candidateAttrs,
    study,
    onParticipationClick,
    setBackgroundTask,
    participationStatus,
    limits: studyLimits && studyLimits.length > 0,
    addFilter,
    onTimeProposalClick,
    partyIdToLimit,
    onSelectAllClick,
    getColumnLabel
  });

  const columnIds = useMemo<string[]>(() => columns.map(({ id }) => id!), [columns]);

  const columnOrder = useMemo<string[]>(
    () => uniq(STATIC_COLUMNS.concat(visibleAttrs.filter((id) => columnIds.includes(id)))),
    [columnIds, visibleAttrs]
  );

  const visibleColumns = useVisibleColumns({ columnIds, study });

  const [updateStudy] = api.useUpdateStudyMutation();

  const selectedRows = useMemo<Record<number, boolean>>(() => {
    const selectedRowIdsMap = {};

    for (const id of selectedRowIds) {
      selectedRowIdsMap[id] = true;
    }

    return selectedRowIdsMap;
  }, [selectedRowIds]);

  const updateStudyVisibleAttrs = useCallback(
    (columns: string[] | Record<string, boolean>) => {
      let ids: string[] = [];

      if (Array.isArray(columns)) {
        ids = columns;
      } else {
        ids = Object.keys(columns).filter((key) => columns[key]);
      }

      setVisibileAttrs(ids);
      updateStudy({ id: study.id, visible_attrs: ids }).unwrap();
    },
    [updateStudy, study.id]
  );

  const handleOnSelectRow = (rows: Record<number, boolean>) => {
    const ids: number[] = [];

    for (const key in rows) {
      ids.push(Number(key));
    }

    onSelectRowIds?.(ids);
  };

  const updateCell = useUpdateParticipation({ updateEntireParticipation });

  const editableColumns = useMemo(
    () => [...EDITABLE_COLUMNS, ...candidateAttrs.map(({ name }) => `extra.${name}`)],
    [candidateAttrs]
  );

  const tabs = useMemo(() => buildColumnTabs(study), [study, buildColumnTabs]);

  const items = useMemo<MultiselectDropdownItem<string>[]>(
    () =>
      columnIds.reduce<MultiselectDropdownItem<string>[]>(
        (acc, id) => (STATIC_COLUMNS.includes(id) ? acc : acc.concat({ label: getColumnLabel(id), value: id })),
        []
      ),
    [columnIds, getColumnLabel]
  );

  const selectedValues = useMemo<string[]>(() => visibleAttrs, [visibleAttrs]);

  const defaultVisibleColumns = useMemo<string[]>(() => {
    const columns: string[] = [...STATIC_COLUMNS];

    columns.push('rating', 'moderator', 'interview_at', 'interview_path');

    if (study.pre_screener) {
      if (serverSideTableEnabled) {
        columns.push('pre_screener_response:ideal', 'pre_screener_response:match_score');
      } else {
        columns.push('preScreenerField.ideal_response', 'preScreenerField.ideal_answers_count');
      }
    }

    study.pre_screener?.fields?.forEach(({ id }) => {
      columns.push(getPreScreenerFieldId(id, serverSideTableEnabled));
    });

    if (study.has_external_candidates_requests) {
      EXTERNAL_ATTRS.forEach(({ name }) => {
        columns.push(`extra.${name}`);
      });
    }

    return columns;
  }, [columnIds, study]);

  useEffect(() => {
    const visible_attrs = uniq(study.visible_attrs.concat(defaultVisibleColumns));
    updateStudy({ id: study.id, visible_attrs }).unwrap();
    setVisibileAttrs(visible_attrs);
  }, []);

  if (isLoading || limitsLoading || limitsProgressLoading) {
    return (
      <div className='min-h-screen w-full'>
        <ReactSkeleton duration={1} className='block h-8 w-full rounded-md bg-gray-50' count={15} />
      </div>
    );
  }

  return (
    <div className='flex flex-1 items-stretch'>
      <div className='relative max-w-full flex-1 overflow-auto border border-t-0 border-gray-200'>
        <Table<Participation>
          id='participations-table'
          className='w-full table-fixed border-t border-gray-200 bg-white'
          data={participations}
          columns={columns}
          columnPinning={{ left: ['id', 'name'] }}
          currentPage={currentPage}
          sortBy={sortBy}
          editableColumns={editableColumns}
          visibleColumns={visibleColumns}
          rowIdentifier='id'
          selectedRows={selectedRows}
          columnOrder={columnOrder}
          onSelectRow={handleOnSelectRow}
          onColumnOrderChange={updateStudyVisibleAttrs}
          onColumnVisibilityChange={updateStudyVisibleAttrs}
          onUpdateCell={updateCell}
          onSort={onSort}
          manualPagination={serverSideTableEnabled}
          manualSorting={serverSideTableEnabled}
        />
      </div>
      <TableSidebar
        className='w-12 border border-l-0 border-gray-200 bg-white'
        selectedValues={selectedValues}
        tabs={tabs}
        items={items}
        onVisibileColumnsChange={updateStudyVisibleAttrs}
      />
    </div>
  );
};

export const ParticipationsTable = memo(ParticipationsTableComponent);
