import { FC } from 'react';
import * as React from 'react';

import pluralize from 'pluralize';

import { CORE_ATTRS, EXTERNAL_ATTRS } from '@components/config';
import { calculateNumber } from '@components/GQSurveyBuilder/utils';
import * as dropdowns from '@components/shared/TableFilters/components/dropdowns';
import { ContactAccess } from '@components/shared/TableFilters/components/dropdowns/ContactAccess';
import { makeStateFromProxy } from '@components/shared/TableFilters/components/segments/utils';
import * as svgs from '@components/shared/TableFilters/components/svgs';
import { FilterDefinition, FilterState, FilterType } from '@components/shared/TableFilters/types';
import * as funcs from '@components/shared/TableFilters/utils/funcs';
import { getDefaultOperator } from '@components/shared/TableFilters/utils/getDefaultOperator';
import { dateFilterDefs } from '@components/StudiesApp/components/StudyPublished/ParticipationTable/components';
import { dateStatuses } from '@components/StudiesApp/components/StudyPublished/ParticipationTable/helpers/dateStatuses';
import { compact } from '@components/utils';

export const attrFunc = (candidateValue: any, state: FilterState<Participation>): boolean => {
  if (!state.allowNotSet && candidateValue === null && state.definition.type !== 'boolean') {
    return false;
  }

  switch (state.definition.type) {
    case 'team_ids':
      return funcs.teamIds(candidateValue, state.operator, state.value);
    case 'free_text':
      return funcs.text(candidateValue, state.operator, state.value);
    case 'number':
      return funcs.number(Number(candidateValue), state.range || {}, state.operator, Number(state.value));
    case 'boolean':
      return funcs.boolean(candidateValue, state.operator, state.value);
    case 'date':
      return funcs.date(candidateValue, state.range || {}, state.operator, state.value);
    case 'text':
    case 'multiple_choice':
      return funcs.multipleChoice(candidateValue, state.operator, state.value);
    default:
      return true;
  }
};

const convertScreenerValue = (value: string | string[] | undefined, type: FilterType): unknown => {
  switch (type) {
    case 'boolean':
      return value === 'Yes';
    default:
      return value;
  }
};

const prefixBasedOnFlag = (filter: string, withFlag: string, withoutFlag: string, flag: boolean) =>
  flag ? `${withFlag}${filter}` : `${withoutFlag}${filter}`;

export const getCustomerFieldId = (field_name: string, serverSideParticipations: boolean) =>
  prefixBasedOnFlag(field_name, 'customer:', '', serverSideParticipations);

export const getSurveyScreenerFieldId = (id: number, serverSideParticipations: boolean) =>
  prefixBasedOnFlag(
    id.toString(),
    'survey_screener_response:answers.',
    'surveyScreenerField.',
    serverSideParticipations
  );

export const getPreScreenerFieldId = (id: number, serverSideParticipations: boolean) =>
  prefixBasedOnFlag(id.toString(), 'pre_screener_response:answers.', 'preScreenerField.', serverSideParticipations);

export const getSurveyScreenerFilter = (filter: string, serverSideParticipations: boolean) =>
  prefixBasedOnFlag(filter, 'survey_screener_response:', 'surveyScreenerField.', serverSideParticipations);

export const getPreScreenerFilter = (filter: string, serverSideParticipations: boolean) =>
  prefixBasedOnFlag(filter, 'pre_screener_response:', 'preScreenerField.', serverSideParticipations);

const ATTR_TYPES_TO_FILTER_TYPES: Record<Attr_['attr_type'], FilterDefinition<Participation>['type']> = {
  text: 'text',
  url: 'text',
  free_text: 'free_text',
  number: 'number',
  boolean: 'boolean',
  datetime: 'date',
  multiple_choice: 'multiple_choice'
};

const SCREENER_FIELD_TYPES_TO_FILTER_TYPES: Record<
  Exclude<
    ScreenerField['field_type'],
    'task' | 'info' | 'start_loom' | 'stop_loom' | 'prototype_test' | 'card_sort' | 'tree_test'
  >,
  FilterDefinition<Participation>['type']
> = {
  short_text: 'free_text',
  long_text: 'free_text',
  email: 'free_text',
  number: 'number',
  website: 'free_text',
  yes_no: 'boolean',
  multiple_choice: 'multiple_choice',
  single_choice: 'multiple_choice',
  number_range: 'number',
  date: 'date',
  location: 'free_text'
};

export const ATTR_TYPES_TO_ICON: Record<Attr_['attr_type'], FC<React.PropsWithChildren<unknown>>> = {
  text: svgs.text,
  url: svgs.url,
  free_text: svgs.text,
  number: svgs.number,
  boolean: svgs.boolean,
  datetime: svgs.date,
  multiple_choice: svgs.multipleChoice
};

type Params = {
  customAttrs: Attr_[];
  segments: CustomerSegment[];
  enableTeams: boolean;
  teams?: Team[];
  preScreenerQuestions: ScreenerField[];
  surveyScreenerQuestions: ScreenerField[];
  hasExternalCandidates?: boolean;
  preScreenerHasIdealAnswers?: boolean;
  incentiveMethod: Study['incentive_method'];
  style: Study['style'];
  hasScreener: boolean;
  onGQ: boolean;
  serverSideParticipations?: boolean;
};

export const buildParticipationsFilterDefs = ({
  preScreenerHasIdealAnswers,
  customAttrs,
  surveyScreenerQuestions,
  preScreenerQuestions,
  hasExternalCandidates,
  segments,
  incentiveMethod,
  style,
  hasScreener,
  onGQ,
  serverSideParticipations = false,
  enableTeams,
  teams = []
}: Params): FilterDefinition<Participation>[] => {
  const segmentDefs = serverSideParticipations
    ? []
    : segments.map(
        (segment) =>
          ({
            id: `segment_${segment.id}`,
            onlyAllowOne: true,
            name: segment.name,
            displayName: 'Segment',
            type: 'segment',
            defaultOperator: 'is',
            Icon: svgs.segment,

            func: (candidate) => {
              // not the most performant
              const applyFilter = (f: ProxyFilterState): boolean => {
                const def = definitions.find((d) => d.id === f.id);
                if (def?.func) {
                  const state = makeStateFromProxy(def as FilterDefinition<any, any>, f);
                  return def.func(candidate as any, state);
                }
                return true;
              };
              return segment.filters.op === 'any'
                ? segment.filters.filters.some(applyFilter)
                : segment.filters.filters.every(applyFilter);
            }
          }) as FilterDefinition<Participation>
      );

  const screenerDefs = [
    ...preScreenerQuestions.map(({ label, id, field_type, options }, i) => ({
      id: getPreScreenerFieldId(id, serverSideParticipations),
      operators: ['single_choice', 'multiple_choice'].includes(field_type)
        ? ['includes_any', 'includes_none', 'includes_all', 'is_blank', 'is_present', 'contains']
        : undefined,
      name: label || `Question #${calculateNumber(preScreenerQuestions, i)}`,
      type: SCREENER_FIELD_TYPES_TO_FILTER_TYPES[field_type],
      defaultOperator: getDefaultOperator(SCREENER_FIELD_TYPES_TO_FILTER_TYPES[field_type]),
      Icon: ATTR_TYPES_TO_ICON[SCREENER_FIELD_TYPES_TO_FILTER_TYPES[field_type]],
      func: ({ screenerResults }: Participation, state: FilterState<Participation>) => {
        const v = convertScreenerValue(screenerResults?.[id]?.value, SCREENER_FIELD_TYPES_TO_FILTER_TYPES[field_type]);
        return attrFunc(v, state);
      },
      values: options
    })),
    ...surveyScreenerQuestions.map(({ label, id, field_type, options }, i) => ({
      id: getSurveyScreenerFieldId(id, serverSideParticipations),
      operators: ['single_choice', 'multiple_choice'].includes(field_type)
        ? ['includes_any', 'includes_none', 'includes_all', 'is_blank', 'is_present', 'contains']
        : undefined,
      name: label || `Question #${calculateNumber(surveyScreenerQuestions, i)}`,
      type: SCREENER_FIELD_TYPES_TO_FILTER_TYPES[field_type],
      defaultOperator: getDefaultOperator(SCREENER_FIELD_TYPES_TO_FILTER_TYPES[field_type]),
      Icon: ATTR_TYPES_TO_ICON[SCREENER_FIELD_TYPES_TO_FILTER_TYPES[field_type]],
      func: ({ screenerResults }: Participation, state: FilterState<Participation>) => {
        const v = convertScreenerValue(screenerResults?.[id]?.value, SCREENER_FIELD_TYPES_TO_FILTER_TYPES[field_type]);
        return attrFunc(v, state);
      },
      values: options
    }))
  ];

  const externalDefs = hasExternalCandidates
    ? [
        ...EXTERNAL_ATTRS.map((attr) => ({
          id: getCustomerFieldId(`extra.${attr.name}`, serverSideParticipations),
          name: attr.label,
          type: 'free_text',
          defaultOperator: getDefaultOperator('free_text'),
          Icon: ATTR_TYPES_TO_ICON[attr.attr_type],
          func: ({ customer }: Participation, state: FilterState<Participation>) => {
            const v = customer?.extra[attr.name];
            return attrFunc(v, state);
          }
        })),
        {
          id: 'recruiting_source',
          name: 'Source',
          type: 'multiple_choice',
          defaultOperator: getDefaultOperator('multiple_choice'),
          values: ['Candidates', 'Respondent', 'Prolific'],
          Icon: svgs.text,
          func: ({ recruiting_source }: Participation, state: FilterState<Participation>) => {
            return attrFunc(recruiting_source, state);
          }
        }
      ]
    : [];

  const attributesDefs = [...CORE_ATTRS, ...customAttrs]
    .filter(
      (attr: Attr_) =>
        ![
          'name',
          'email',
          'timezone',
          'phone_number',
          'current_year_usd_amount_in_cents',
          'all_time_usd_amount_in_cents'
        ].includes(attr.name)
    )
    .map((attr: Attr_) => {
      const filterType = ATTR_TYPES_TO_FILTER_TYPES[attr.attr_type];

      return {
        id: getCustomerFieldId(attr.core ? attr.name : `extra.${attr.name}`, serverSideParticipations),
        type: filterType,
        name: attr.label,
        defaultOperator: getDefaultOperator(filterType),
        Icon: ATTR_TYPES_TO_ICON[attr.attr_type],
        func: ({ customer }: Participation, state: FilterState<Participation>) => {
          const v = attr.core ? customer?.[attr.name] : customer?.extra[attr.name];
          return attrFunc(v, state);
        }
      };
    });

  const defaultDefs = compact([
    !serverSideParticipations && {
      name: 'Name',
      id: 'name',
      type: 'free_text',
      defaultOperator: 'contains',
      Icon: svgs.text,
      func: ({ customer }: Participation, state: FilterState<Participation>) => {
        return attrFunc(customer?.name, state);
      }
    },
    {
      name: 'Email',
      id: getCustomerFieldId('email', serverSideParticipations),
      type: 'free_text',
      defaultOperator: 'contains',
      Icon: svgs.text,
      func: ({ customer }: Participation, state: FilterState<Participation>) => {
        return attrFunc(customer?.email, state);
      }
    },
    {
      name: 'Phone number',
      id: getCustomerFieldId('phone_number', serverSideParticipations),
      type: 'free_text',
      defaultOperator: 'contains',
      Icon: svgs.text,
      func: ({ customer }: Participation, state: FilterState<Participation>) => {
        return attrFunc(customer?.phone_number, state);
      }
    },
    {
      name: 'Timezone',
      id: getCustomerFieldId('timezone', serverSideParticipations),
      type: 'free_text',
      defaultOperator: 'is',
      Icon: svgs.text,
      func: ({ customer }: Participation, state: FilterState<Participation>) => {
        return attrFunc(customer?.timezone, state);
      }
    },
    {
      id: getCustomerFieldId('participations_count', serverSideParticipations),
      type: 'number',
      name: 'Number of studies',
      Icon: svgs.number,
      sortIconType: 'number',
      defaultOperator: 'is',
      func: ({ customer }: Participation, state: FilterState<Participation>) => {
        return attrFunc(customer?.participations_count, state);
      }
    },
    {
      id: getCustomerFieldId('current_year_usd_amount_in_cents', serverSideParticipations),
      type: 'number',
      Icon: svgs.number,
      sortIconType: 'number',
      defaultOperator: 'is',
      name: 'Incentives Paid (Current Year)',
      func: ({ customer }: Participation, state: FilterState<Participation>) => {
        return attrFunc(customer?.current_year_usd_amount_in_cents, state);
      }
    },
    {
      id: getCustomerFieldId('eligibility', serverSideParticipations),
      onlyAllowOne: true,
      type: 'boolean',
      name: 'Eligible',
      defaultOperator: 'is',
      defaultValue: true,
      Component: dropdowns.Eligibility,
      Icon: svgs.eligibility,
      func: ({ customer }: Participation, state: FilterState<Participation>) => {
        return attrFunc(customer?.eligible, state);
      }
    },
    {
      id: getCustomerFieldId('all_time_usd_amount_in_cents', serverSideParticipations),
      type: 'number',
      Icon: svgs.number,
      sortIconType: 'number',
      defaultOperator: 'is',
      name: 'Incentives Paid (All Time)',
      func: ({ customer }: Participation, state: FilterState<Participation>) => {
        return attrFunc(customer?.all_time_usd_amount_in_cents, state);
      }
    },

    {
      id: getCustomerFieldId('last_contacted_at', serverSideParticipations),
      type: 'date',
      name: 'Last invited date',
      defaultOperator: 'is',
      Component: dropdowns.Date,
      Icon: svgs.date,
      func: ({ customer }: Participation, state: FilterState<Candidate>) => {
        if (!customer?.last_contacted_at) return !!state.allowNotSet;
        return funcs.date(new Date(customer.last_contacted_at), state.range || {}, state.operator, state.value);
      }
    },
    {
      id: getCustomerFieldId('created_at', serverSideParticipations),
      type: 'date',
      name: 'Join date',
      defaultOperator: 'is_greater',
      Component: dropdowns.Date,
      Icon: svgs.date,
      func: ({ customer }: Participation, state: FilterState<Candidate>) => {
        if (!customer?.created_at) return !!state.allowNotSet;
        return funcs.date(new Date(customer.created_at), state.range || {}, state.operator, state.value);
      }
    },
    preScreenerHasIdealAnswers && {
      id: serverSideParticipations ? 'pre_screener_response:ideal' : 'ideal_pre_screener_response',
      type: 'boolean',
      name: 'Matches ideal answers?',
      Component: dropdowns.Boolean,
      Icon: svgs.boolean,
      defaultOperator: 'is',
      func: ({ ideal_pre_screener_response }: Participation, state: FilterState<Participation>) => {
        return funcs.boolean(ideal_pre_screener_response, state.operator, state.value);
      }
    },
    preScreenerHasIdealAnswers && {
      id: 'pre_screener_response:match_score',
      type: 'number',
      name: '# of ideal answers',
      Component: dropdowns.Number,
      Icon: svgs.number,
      defaultOperator: 'is',
      func: ({ pre_screener_response_match_score }: Participation, state: FilterState<Participation>) => {
        return attrFunc(pre_screener_response_match_score, state);
      }
    },
    {
      name: 'Token',
      id: 'token',
      type: 'free_text',
      defaultOperator: 'is',
      Icon: svgs.text,
      func: ({ token }: Participation, state: FilterState<Participation>) => {
        return attrFunc(token, state);
      }
    },
    {
      id: 'rating',
      name: 'Participant rating',
      type: 'number',
      Icon: svgs.number,
      component: dropdowns.Number,
      defaultOperator: 'is',
      func: ({ rating }: Participation, state: FilterState<Participation>) => {
        return attrFunc(rating, state);
      }
    },
    incentiveMethod == 'coupon' && {
      id: 'incentive_coupon',
      name: 'Coupon code',
      type: 'free_text',
      Icon: svgs.text,
      component: dropdowns.Text,
      defaultOperator: 'is',
      func: ({ incentive_coupon }: Participation, state: FilterState<Participation>) => {
        return attrFunc(incentive_coupon, state);
      }
    },
    style === 'video_call' && {
      id: 'moderator',
      type: 'free_text',
      name: 'Moderator',
      Icon: svgs.text,
      defaultOperator: 'contains',
      func: ({ moderator }: Participation, state: FilterState<Participation>) => {
        return attrFunc(moderator?.name || null, state);
      }
    },
    enableTeams &&
      teams &&
      teams.length > 0 && {
        id: getCustomerFieldId('team_ids', serverSideParticipations),
        type: 'team_ids',
        name: 'Contact Access',
        defaultOperator: 'includes_any',
        operators: ['includes_any', 'includes_all', 'includes_none'],
        Icon: svgs.team,
        renderValue: (value: string | string[]) => {
          if (value.includes('all')) return 'Anyone';
          if (value.includes('none')) return 'No one';
          if (value.length === 1) {
            const teamName = teams?.find(({ id }) => id === parseInt(value[0]))?.name;
            if (teamName) {
              return teamName;
            }
          }
          return pluralize('team', value.length, true);
        },
        func: ({ customer }: Participation, state: FilterState<Participation>) => {
          return attrFunc({ contact_access: customer?.contact_access, team_ids: customer?.team_ids }, state);
        },
        Component: (
          props: JSX.IntrinsicAttributes &
            FilterState<any, string[], any> & {
              onChange: (state: Partial<FilterState<any, string[], any>>) => void;
              values?: string[] | undefined;
              localValue?: string[] | undefined;
            } & { teams: Team[] } & { children?: React.ReactNode | undefined }
        ) => <ContactAccess {...props} teams={teams} />
      }
  ]);

  const dateDefs = dateStatuses(style, hasScreener, onGQ).map(dateFilterDefs);

  const definitions = [
    ...attributesDefs,
    ...defaultDefs,
    ...externalDefs,
    ...screenerDefs,
    ...segmentDefs,
    ...dateDefs
  ] as FilterDefinition<Participation>[];

  return definitions;
};
