import * as React from 'react';

import { FilterType } from '@components/shared/TableFilters/types';
import { Sort0to9SVG, Sort9to0SVG, SortAzSVG, SortZaSVG } from '@components/svgs';

import { SortType } from '../../types';

type SortCopy = {
  text: string;
  Icon: React.FC<React.PropsWithChildren<any>>;
};

export const FILTER_TO_TO_SORT_ICON_MAPPING: Partial<Record<FilterType, SortType>> = {
  number: 'number',
  text: 'text',
  free_text: 'text',
  date: 'date'
};

export const ATTR_TO_SORT_ICON_MAPPING: Partial<Record<Attr_['attr_type'], SortType>> = {
  number: 'number',
  text: 'text',
  free_text: 'text',
  datetime: 'date'
};

export const QUESTION_TYPE_TO_SORT_ICON_MAPPING: Partial<Record<ScreenerFieldType, SortType>> = {
  number: 'number',
  number_range: 'number',
  date: 'date'
};

export const SORT_ICONS: Record<SortType, Record<'ascending' | 'descending', SortCopy>> = {
  text: {
    ascending: {
      text: 'Sort A to Z',
      Icon: SortAzSVG
    },
    descending: {
      text: 'Sort Z to A',
      Icon: SortZaSVG
    }
  },
  boolean: {
    ascending: {
      text: 'Sort No to Yes',
      Icon: Sort0to9SVG
    },
    descending: {
      text: 'Sort Yes to No',
      Icon: Sort9to0SVG
    }
  },
  number: {
    ascending: {
      text: 'Sort low to high',
      Icon: Sort0to9SVG
    },
    descending: {
      text: 'Sort high to low',
      Icon: Sort9to0SVG
    }
  },
  date: {
    ascending: {
      text: 'Sort oldest to newest',
      Icon: Sort0to9SVG
    },
    descending: {
      text: 'Sort newest to oldest',
      Icon: Sort9to0SVG
    }
  }
};
