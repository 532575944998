import { isSameDay } from 'date-fns';

type BookingField =
  | 'start_time'
  | 'duration'
  | 'moderators'
  | 'observers'
  | 'candidate_location'
  | 'team_location'
  | 'live_stream_enabled';

type FieldConfig = {
  path: string;
  type: 'candidate' | 'team';
};

export const FIELD_CONFIGS: Record<BookingField, FieldConfig> = {
  start_time: { path: 'candidate_event.time.start_time', type: 'candidate' },
  duration: { path: 'candidate_event.time.duration_in_minutes', type: 'candidate' },
  moderators: { path: 'candidate_event.moderators', type: 'candidate' },
  candidate_location: {
    path: 'candidate_event.conferencing.provider',
    type: 'candidate'
  },
  observers: { path: 'team_event.observers', type: 'team' },
  team_location: { path: 'team_event.conferencing.provider', type: 'team' },
  live_stream_enabled: { path: 'team_event.live_stream_enabled', type: 'team' }
} as const;

const getValueFromPath = (obj: any, path: string): any => {
  return path.split('.').reduce((acc, key) => (acc ? acc[key] : undefined), obj);
};

export const hasChangeFor = (
  field: BookingField,
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>
): boolean => {
  const config = FIELD_CONFIGS[field];
  const originalValue = getValueFromPath(original, config.path);
  const changedValue = getValueFromPath(changes, config.path);

  if (field === 'live_stream_enabled') {
    return changedValue !== undefined;
  }

  if (field === 'candidate_location' || field === 'team_location') {
    const eventType = field === 'candidate_location' ? 'candidate_event' : 'team_event';
    const originalEvent = original[eventType];
    return originalEvent !== undefined && changedValue !== undefined;
  }

  if (!originalValue || !changedValue) {
    return false;
  }

  return true;
};

export const getValueFor = <T>(
  field: BookingField,
  bookingDetail: CalendarBookingDetail | DeepPartial<CalendarBookingDetail>,
  defaultValue: T
): T => {
  const config = FIELD_CONFIGS[field];
  const value = getValueFromPath(bookingDetail, config.path);

  if (value === undefined || value === null) {
    return defaultValue;
  }

  return value as T;
};

export const hasModeratorChanges = (changes: DeepPartial<CalendarBookingDetail>): boolean => {
  return (
    getValueFromPath(changes, FIELD_CONFIGS.moderators.path) !== undefined ||
    getValueFromPath(changes, FIELD_CONFIGS.candidate_location.path) !== undefined
  );
};

export const hasObserverChanges = (changes: DeepPartial<CalendarBookingDetail>): boolean => {
  return (
    getValueFromPath(changes, FIELD_CONFIGS.observers.path) !== undefined ||
    getValueFromPath(changes, FIELD_CONFIGS.team_location.path) !== undefined ||
    getValueFromPath(changes, FIELD_CONFIGS.live_stream_enabled.path) !== undefined
  );
};

export const hasSettingsChanges = (changes: DeepPartial<CalendarBookingDetail>): boolean => {
  return (
    getValueFromPath(changes, FIELD_CONFIGS.start_time.path) !== undefined ||
    getValueFromPath(changes, FIELD_CONFIGS.duration.path) !== undefined
  );
};

export type EmailChange = {
  type: 'new' | 'updated' | 'cancelled';
  guest: CalendarEventGuest;
  eventType: 'candidate' | 'team';
};

export const hasEmailChanges = (
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>
): boolean => {
  const hasModeratorGuestChanges = hasChangeFor('moderators', original, changes);
  const hasObserverGuestChanges = hasChangeFor('observers', original, changes);
  const hasTimeChanges = hasChangeFor('start_time', original, changes);
  const hasDurationChanges = hasChangeFor('duration', original, changes);

  if (!hasModeratorGuestChanges && !hasObserverGuestChanges && !hasTimeChanges && !hasDurationChanges) {
    return false;
  }

  return getEmailChanges(original, changes).length > 0;
};

type EventWithGuests = DeepPartial<{
  moderators?: CalendarEventGuest[];
  observers?: CalendarEventGuest[];
  additional_guests?: CalendarEventGuest[];
}>;

const getEventGuests = (event?: EventWithGuests, includeAdditionalGuests: boolean = false): CalendarEventGuest[] => {
  const guests = [
    ...(event?.moderators || []),
    ...(event?.observers || []),
    ...(includeAdditionalGuests ? event?.additional_guests || [] : [])
  ];
  return guests.filter((guest): guest is CalendarEventGuest => guest !== undefined);
};

const findGuestByEmail = (guests: CalendarEventGuest[], email: string) => guests.find((guest) => guest.email === email);

const hasGuestInChanges = (changes: EmailChange[], email: string) =>
  changes.some((change) => change.guest.email === email);

const collectGuestChanges = (
  originalGuests: CalendarEventGuest[],
  changedGuests: CalendarEventGuest[],
  eventType: 'candidate' | 'team',
  result: EmailChange[]
) => {
  const originalEmails = new Set(originalGuests.map((guest) => guest.email));
  const changedEmails = new Set(changedGuests.map((guest) => guest.email));

  changedGuests.forEach((guest) => {
    if (!originalEmails.has(guest.email) && !hasGuestInChanges(result, guest.email)) {
      result.push({ type: 'new', guest, eventType });
    }
  });

  originalGuests.forEach((guest) => {
    if (!changedEmails.has(guest.email) && !hasGuestInChanges(result, guest.email)) {
      result.push({ type: 'cancelled', guest, eventType });
    }
  });
};

const collectAdditionalGuestChanges = (
  originalGuests: CalendarEventGuest[],
  changedGuests: CalendarEventGuest[],
  eventType: 'candidate' | 'team',
  result: EmailChange[]
) => {
  const originalEmails = new Set(originalGuests.map((guest) => guest.email));
  const changedEmails = new Set(changedGuests.map((guest) => guest.email));

  changedGuests.forEach((guest) => {
    if (!originalEmails.has(guest.email) && !hasGuestInChanges(result, guest.email)) {
      result.push({ type: 'new', guest, eventType });
    }
  });

  originalGuests.forEach((guest) => {
    if (!changedEmails.has(guest.email) && !hasGuestInChanges(result, guest.email)) {
      result.push({ type: 'cancelled', guest, eventType });
    }
  });
};

const collectTimeChanges = (guests: CalendarEventGuest[], eventType: 'candidate' | 'team', result: EmailChange[]) => {
  guests.forEach((guest) => {
    if (!hasGuestInChanges(result, guest.email)) {
      result.push({ type: 'updated', guest, eventType });
    }
  });
};

const collectEventChanges = (
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>,
  eventType: 'candidate' | 'team',
  result: EmailChange[]
) => {
  const eventKey = `${eventType}_event` as const;
  const originalEvent = original[eventKey];
  const changedEvent = changes[eventKey];

  const hasGuestListChanges =
    (eventType === 'candidate' && 'moderators' in (changedEvent || {})) ||
    (eventType === 'team' && 'observers' in (changedEvent || {})) ||
    'additional_guests' in (changedEvent || {});

  if (hasGuestListChanges) {
    const originalGuests = getEventGuests(originalEvent);
    const changedGuests = getEventGuests(changedEvent, true);
    collectGuestChanges(originalGuests, changedGuests, eventType, result);

    if (changedEvent?.additional_guests !== undefined) {
      const originalAdditionalGuests = (originalEvent?.additional_guests || []) as CalendarEventGuest[];
      const changedAdditionalGuests = (changedEvent.additional_guests || []) as CalendarEventGuest[];
      collectAdditionalGuestChanges(originalAdditionalGuests, changedAdditionalGuests, eventType, result);
    }
  }
};

type CandidateEvent = CalendarBookingDetailEvent & {
  moderator_id: number;
  moderator_email: string;
  moderators?: CalendarEventGuest[];
};
type TeamEvent = CalendarBookingDetailEvent & { observers?: CalendarEventGuest[]; live_stream_enabled?: boolean };

const isCandidateEvent = (event: any): event is CandidateEvent => event && 'moderator_id' in event;
const isTeamEvent = (event: any): event is TeamEvent => event && 'observers' in event;

export const getEmailChanges = (
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>
): EmailChange[] => {
  const result: EmailChange[] = [];

  collectEventChanges(original, changes, 'candidate', result);
  collectEventChanges(original, changes, 'team', result);

  if (hasChangeFor('start_time', original, changes) || hasChangeFor('duration', original, changes)) {
    if (original.candidate_event) {
      const candidateGuests = getEventGuests(original.candidate_event, true);
      collectTimeChanges(candidateGuests, 'candidate', result);
    }

    if (original.team_event) {
      const teamGuests = getEventGuests(original.team_event, true);
      collectTimeChanges(teamGuests, 'team', result);
    }
  }

  return result;
};

export const hasDateChange = (
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>
): boolean => {
  if (!hasChangeFor('start_time', original, changes)) {
    return false;
  }

  const originalDate = getValueFor<Date>('start_time', original, new Date());
  const changedDate = getValueFor<Date>('start_time', changes, new Date());
  return !isSameDay(originalDate, changedDate);
};

export const hasTimeChange = (
  original: CalendarBookingDetail,
  changes: DeepPartial<CalendarBookingDetail>
): boolean => {
  if (!hasChangeFor('start_time', original, changes)) {
    return false;
  }

  const originalDate = getValueFor<Date>('start_time', original, new Date());
  const changedDate = getValueFor<Date>('start_time', changes, new Date());
  return originalDate.getHours() !== changedDate.getHours() || originalDate.getMinutes() !== changedDate.getMinutes();
};
